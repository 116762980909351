import { NUXT_APP_BIBLE_API } from "~/env";
import { useAxios } from "../useAxios";

const { axios, loading } = useAxios();

const bibleApi = NUXT_APP_BIBLE_API;

async function searchVerses({ bibleCode, searchPlace, searchValue, wholeWord, withRegister }: any) {
    const params = {
        bibleCode,
        searchPlace,
        searchValue,
        wholeWord,
        withRegister,
    };

    const response = await axios.post(bibleApi + `/search/verses`, params);
    const { results } = response || {};

    return results;
}

export {
    loading,
    searchVerses
}