import { findBook } from "../useBible";
import { searchVerses as _searchVerses, loading } from "./search.api";


export function useSearch() {
    const resultsAllColl = ref<any[]>([]);
    const resultsBooksColl = ref<any[]>([]);
    const resultsColl = ref<any[]>([]);
    const filter = ref<any>({
        bookCode: '',
        bookPlace: ''
    });

    function toAppSearchModel(verse: any) {
        const bookModel = findBook({
            bibleCode: verse.bible_code,
            bookCode: verse.book_code
        })

        const resultModel = {
            _id: verse._id,
            bibleCode: verse.bible_code,
            bookCode: verse.book_code,
            bookCodeOrig: verse.book_code_orig,
            bookNameCanonLong: bookModel.bookNameCanonLong,
            bookPlace: bookModel.bookPlace,
            chapterNum: verse.chapter_num,
            verseText: verse.verse_text,
            verseNum: verse.verse_num
        }

        return resultModel;
    }

    async function searchVerses(params: any) {
        const verses: any[] = await _searchVerses(params);

        resultsAllColl.value = verses.map(verse => toAppSearchModel(verse))
        resultsBooksColl.value = groupBy(resultsAllColl.value, el => el.bookCode).map(([g, vColl]: any) => {
            return {
                bookCode: g,
                bookCodeOrig: vColl[0].bookCodeOrig,
                amount: vColl.length
            }
        });

        updateFilterValues();
    }

    function setFilter({ bookCode, bookPlace }: any) {
        filter.value.bookCode = bookCode;
        filter.value.bookPlace = bookPlace;
    }

    function updateFilterValues() {
        resultsColl.value = resultsAllColl.value;

        if (filter.value.bookCode)
            resultsColl.value = resultsAllColl.value.filter(v => v.bookCode == filter.value.bookCode)

        if (filter.value.bookPlace) {
            if (filter.value.bookPlace == 'ot' || filter.value.bookPlace == 'nt')
                resultsColl.value = resultsAllColl.value.filter(v => v.bookPlace == filter.value.bookPlace)

            let booksGroupsColl = groupBy(resultsAllColl.value, el => el.bookCode).map(([g, vColl]: any) => {
                return {
                    bookCode: g,
                    bookCodeOrig: vColl[0].bookCodeOrig,
                    bookPlace: findBook({ bibleCode: vColl[0].bibleCode, bookCode: g }).bookPlace,
                    amount: vColl.length
                }
            });

            resultsBooksColl.value = booksGroupsColl.filter(v =>
                filter.value.bookPlace == 'all' ||
                v.bookPlace == filter.value.bookPlace
            );
        }
    }

    watch(
        () => [filter.value],
        () => updateFilterValues(),
        { deep: true, immediate: true })

    return {
        searchVerses,
        setFilter,
        filter,
        loading,
        resultsAllColl,
        resultsColl,
        resultsBooksColl,
    }
}